import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";

const Background = styled.div`
  background-color: ${(props) => props.theme.palette.backgrounds.brand};
`;

const Header = ({ siteTitle }) => (
  <Background>
    <Spacings.Inset scale="big">
      <Text textStyle="headline6">
        <Link
          to="/"
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          {siteTitle}
        </Link>
      </Text>
    </Spacings.Inset>
  </Background>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
