import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import Layout from "./layout";
import { MDXProvider } from "@mdx-js/react";

const Wrapper = (props) => <main className="mdx-wrapper" {...props} />;

const MdxPage = ({ data }) => (
  <Layout>
    <Spacings.Inset>
      <MDXProvider
        components={{
          h1: (props) => <Text {...props} textStyle="headline1" />,
          h2: (props) => <Text {...props} textStyle="headline2" />,
          h3: (props) => <Text {...props} textStyle="headline3" />,
          h4: (props) => <Text {...props} textStyle="headline4" />,
          h5: (props) => <Text {...props} textStyle="headline5" />,
          h6: (props) => <Text {...props} textStyle="headline6" />,
          p: (props) => <Text {...props} isUncropped textStyle="body" />,
          wrapper: Wrapper,
        }}
      >
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>
    </Spacings.Inset>
  </Layout>
);

MdxPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query MdxQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
      body
    }
  }
`;

export default MdxPage;
