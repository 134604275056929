import { graphql, StaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import NavSection from "./navSection";

const SidebarSectionsOrder = {
  Headless: 0,
  Styles: 1,
  Layouts: 2,
  "Atoms/Molecules": 3,
  Molecules: 4,
  Organisms: 5,
};

const StyledNav = styled(Spacings.Stack)`
  flex: 1;
  overflow-y: auto;
`;

const sortNodesByOrder = (firstNode, secondNode) => {
  const firstValue =
    SidebarSectionsOrder[firstNode] || SidebarSectionsOrder["Headless"];
  const secondValue =
    SidebarSectionsOrder[secondNode] || SidebarSectionsOrder["Headless"];
  return firstValue - secondValue;
};

const Nav = () => (
  <StaticQuery
    query={graphql`
      query Nav {
        site {
          siteMetadata {
            title
          }
        }
        allMdx {
          edges {
            node {
              id
              frontmatter {
                name
                menu
                route
              }
            }
          }
        }
      }
    `}
    render={({ allMdx: { edges } }) => (
      <StyledNav>
        {edges
          .reduce(
            (
              sections,
              {
                node: {
                  frontmatter: { menu = null },
                },
              },
            ) => (!sections.includes(menu) ? [...sections, menu] : sections),
            [],
          )
          .sort(sortNodesByOrder)
          .map((section) => (
            <NavSection
              key={section}
              title={section}
              nodes={edges.filter(
                ({ node }) => node.frontmatter.menu === section,
              )}
            />
          ))}
      </StyledNav>
    )}
  />
);

export default Nav;
